import React, { FC } from "react"

export const AudioDisabledIcon: FC = () => {
  return (
    <svg viewBox="0 0 40 40" fill="none">
      <path
        d="M19 13L14 17H10V23H14L19 27V13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M30 17L24 23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M24 17L30 23"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}
