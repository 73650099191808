import { Container } from "@components/Layout"
import { ParagraphS } from "@components/Typography"
import { breakpoints } from "@config/theme"
import { useBreakpoint } from "@lib/hooks/use-breakpoint"
import { CbVideo as CbVideoModel } from "@lib/kontent/models/CbVideo"
import { FC, useEffect, useRef, useState } from "react"
import LoadingSpinner from "./LoadingSpinner"
import { PlayIcon } from "./PlayIcon"
import * as S from "./elements"

type CbVideoProps = CbVideoModel

export const CbVideo: FC<CbVideoProps> = ({
  preview_image,
  video: desktopVideo,
  video_height,
  video_width,
  mobile_preview_image,
  mobile_video,
  mobile_video_height,
  mobile_video_width,
  annotation_text,
  display_wide_boxed,
}) => {
  const innerRef = useRef<HTMLVideoElement>(null)
  const isDesktop = useBreakpoint(breakpoints.large)
  const [status, setStatus] = useState<"idle" | "loading" | "playing">("idle")
  const isBoxed = display_wide_boxed.value[0].codename === "boxed_variant"
  const previewImage = isDesktop ? preview_image : mobile_preview_image
  const video = isDesktop ? desktopVideo : mobile_video
  const width = isDesktop ? video_width.value : mobile_video_width.value
  const height = isDesktop ? video_height.value : mobile_video_height.value

  useEffect(() => {
    const player = innerRef.current

    if (!player) return

    const handlePlayEvent = () => {
      setStatus("playing")
    }

    player.addEventListener("playing", handlePlayEvent)
    return () => {
      player.removeEventListener("playing", handlePlayEvent)
    }
  }, [innerRef])

  return (
    <S.Wrapper>
      <S.Parent
        position="relative"
        m={"0 auto"}
        width={isBoxed && "54rem"}
        videoHeight={height}
        videoWidth={width}
        maxWidth={
          isBoxed
            ? [
                "calc(100% - 4rem)",
                "calc(100% - 4rem)",
                "calc(100% - 4rem)",
                "calc(100% - 8rem)",
              ]
            : "120rem"
        }
      >
        <S.Video
          src={video.value[0].url}
          height={height}
          width={width}
          ref={innerRef}
          controls
        />
        {previewImage && (
          <S.PreviewImg
            show={status !== "playing"}
            src={previewImage.value[0].url}
            alt="preview-img"
          />
        )}

        <S.Content status={status}>
          <S.PlayButton
            onClick={requestVideoPlay}
            status={status}
            tabIndex={-1}
            disabled={status === "loading"}
          >
            {status === "loading" ? <LoadingSpinner size={40} /> : <PlayIcon />}
          </S.PlayButton>
        </S.Content>
      </S.Parent>
      <Container maxWidth={isBoxed ? "54rem" : "75rem"} py=".5rem">
        <ParagraphS color="text.light">{annotation_text.value}</ParagraphS>
      </Container>
    </S.Wrapper>
  )

  function requestVideoPlay() {
    if (innerRef.current) {
      setStatus("loading")
      innerRef.current.play()
      // Remove focus from the play button to the player
      innerRef.current.focus()
    }
  }
}
