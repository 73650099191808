import { Container } from "@components/Layout"
import { TitleL } from "@components/Typography"
import { CbIsolatedTitle } from "@lib/kontent/models/CbIsolatedTitle"
import { FC } from "react"

type Props = CbIsolatedTitle

const IsolatedTitle: FC<Props> = ({ title, alignment, title_tag }) => {
  const align = alignment?.value[0]?.codename || "center"
  const headingType = title_tag.value[0] ? title_tag.value[0].codename : "h2"

  return (
    <Container
      mt={{ _: "3rem", small: "4rem", large: "5rem" }}
      mb={{ _: "2rem", small: "3rem", large: "4rem" }}
      display="flex"
      justifyContent={
        align === "left"
          ? "flex-start"
          : align === "right"
          ? "flex-end"
          : "center"
      }
    >
      <TitleL
        as={headingType as any}
        mb={0}
        maxWidth="50rem"
        textAlign={
          align === "left" ? "left" : align === "right" ? "right" : "center"
        }
      >
        {title.value}
      </TitleL>
    </Container>
  )
}

export default IsolatedTitle
