import { SliderNavButton, Track, useSlider } from "@components/Slider"
import React, { FC, useRef } from "react"
import * as S from "./Track.elements"

type ProductRowTrackProps = {
  itemCount: number
  children: React.ReactNode
}

export const ProductRowTrack: FC<ProductRowTrackProps> = ({
  itemCount,
  children,
}) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const { onTouchEnd, prev, next, index, canMoveRight } = useSlider({
    trackRef,
  })

  return (
    <div>
      <S.NavButtons isVisible={itemCount > 3}>
        <SliderNavButton
          onClick={prev}
          direction="prev"
          disabled={index === 0}
        />
        <SliderNavButton
          onClick={next}
          direction="next"
          disabled={!canMoveRight}
        />
      </S.NavButtons>
      <Track
        ref={trackRef}
        onTouchEnd={onTouchEnd}
        overflowY="hidden"
        display="flex"
      >
        {children}
      </Track>
    </div>
  )
}
