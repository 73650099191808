import { Box } from "@components/Layout"
import { breakpoints } from "@config/theme"
import styled from "styled-components"
import { gridColumn } from "styled-system"
import { lighten } from "polished"

export const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  left: 0;

  svg {
    height: 1rem;
    width: 1rem;
    fill: ${(p) => p.theme.colors.purple};
    transition: transform 0.25s ease-in-out;
  }
`

Content.defaultProps = {
  bottom: ["1rem", "1rem", "2rem"],
  padding: ["0 1rem", "0 1rem", "0 2rem"],
}

export const Image = styled.img`
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  height: 100%;
  width: 100%;
  left: 0;
  object-fit: cover;
  object-position: right bottom;
  position: absolute;
  top: 0;
  transform-origin: center;
`

export const Anchor = styled.a`
  ${gridColumn};
  display: block;
  position: relative;
  background: ${(p) => p.theme.colors.card.default};
  transition: background 0.1s ease-in-out;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  color: ${(p) => p.theme.colors.text.dark};

  &:hover,
  &:active,
  &:focus {
    background: ${(p) => lighten(0.05, p.theme.colors.card.active)};
  }

  &::before {
    padding-top: 120%;
    content: "";
    display: block;
    @media screen and (min-width: ${breakpoints.small}) {
      padding-top: 120%;
    }
  }
  ${Image} {
    transform: scale(1.02);
  }

  &:hover {
    svg {
      transform: translateX(0.25rem);
    }
  }
`
