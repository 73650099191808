import React, { FC } from "react"
// import { Box, SliderNavButton, breakpoints, theme, Text } from '@gassan-ui'
import { rem, rgba } from "polished"
import * as Elements from "@lib/kontent/models/Elements"
import styled from "styled-components"
import { Box } from "@components/Layout"
import { ParagraphS } from "@components/Typography"
import { breakpoints, theme } from "@config/theme"
import { SliderNavButton } from "@components/Slider"

const NavButton = styled(SliderNavButton)`
  background: blue;
  opacity: 1 !important;
  border-radius: 100%;
  height: 2rem;
  border: 0;
  width: 2rem;
  background: ${theme.colors.beige};
  color: ${(p) => (p.disabled ? theme.colors.text.light : theme.colors.purple)};

  @media screen and (min-width: ${breakpoints.small}) {
    width: 3.5rem;
    height: 3.5rem;
  }

  svg {
    fill: currentColor;
    width: 1.25rem;
  }
`

type Props = {
  images: Elements.AssetsElement["value"]
  index: number
  onPrev: () => void
  onNext: () => void
}

const SlideNav: FC<Props> = ({ images, index, onPrev, onNext }) => {
  return (
    <>
      <Box
        position="absolute"
        top="50%"
        left={["0.5rem", "1rem", "1rem", "-1.75rem"]}
        width={[
          "calc(100% - 1rem)",
          "calc(100% - 2rem)",
          "calc(100% - 2rem)",
          "calc(100% + 3.5rem)",
        ]}
        style={{ transform: "translateY(-50%)" }}
        display="flex"
        justifyContent="space-between"
        zIndex={2}
      >
        <NavButton onClick={onPrev} direction="prev" disabled={index === 0} />
        <NavButton
          onClick={onNext}
          direction="next"
          disabled={index === images.length - 1}
        />
      </Box>
      <Box
        position="absolute"
        bottom={["1rem", "1.5rem"]}
        left={["0.5rem", "1.5rem"]}
        borderRadius={rem(22)}
        bg={rgba("#ffffff", 0.7)}
        display="flex"
        alignItems="center"
        textAlign="center"
        zIndex={2}
        p="0.25rem 0.5rem"
      >
        <ParagraphS>{`${index + 1}/${images.length}`}</ParagraphS>
      </Box>
    </>
  )
}

export default SlideNav
