import { ArrowRight } from "@components/Icons"
import { useIntl } from "@components/Intl"
import { TitleM } from "@components/Typography"
import { getKontentImage } from "@lib/kontent"
import { CbSingleCollectionCollections } from "@lib/kontent/models/CbSingleCollectionCollections"
import Link from "next/link"
import { FC } from "react"
import * as S from "./elements"

type CollectionCardProps = CbSingleCollectionCollections

export const CollectionCard: FC<CollectionCardProps> = ({
  link_text,
  link_url,
  image,
}) => {
  const { locale } = useIntl()

  return (
    <Link href={`/${locale}${link_url.value}`} passHref>
      <S.Anchor>
        <S.Image
          src={getKontentImage(image.value[0].url, {
            width: 600,
          })}
          loading="lazy"
        />
        <S.Content display={"block"}>
          <TitleM>{link_text.value}</TitleM>
          <ArrowRight />
        </S.Content>
      </S.Anchor>
    </Link>
  )
}
