import React, { useState } from "react"
import * as S from "./elements"

interface Props {
  src: string
  previewImg?: string
}

const VideoInline: React.FC<Props> = ({ src, previewImg }) => {
  const [showVideo, setShowVideo] = useState(false)

  return (
    <>
      <video
        src={src}
        playsInline
        preload="none"
        muted
        loop
        autoPlay
        onLoadedData={() => setShowVideo(true)}
      />
      {previewImg && (
        <S.PreviewImg show={!showVideo} src={previewImg} alt="preview-img" />
      )}
    </>
  )
}

export default VideoInline
