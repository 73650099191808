import { breakpoints } from "@config/theme"
import styled from "styled-components"

export const NavButtons = styled.div<{ isVisible: boolean }>`
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (min-width: ${breakpoints.large}) {
    visibility: ${(p) => (p.isVisible ? "visible" : "hidden")};
  }
`
