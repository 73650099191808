import LazyImage from "@components/LazyImage"
import { Paragraph, TitleLAlt } from "@components/Typography"
import { getImageRatio } from "@lib/get-image-ratio"
import { getKontentImage } from "@lib/kontent"
import { CbTextImageBoxed } from "@lib/kontent/models/CbTextImageBoxed"
import { FC } from "react"
import * as S from "./elements"

type TextImageBoxedProps = CbTextImageBoxed

export const TextImageBoxed: FC<TextImageBoxedProps> = ({
  title,
  description,
  image,
  tablet_image,
  order,
}) => {
  const ratio = image && getImageRatio(image)
  const tabletRatio = tablet_image && getImageRatio(tablet_image)
  const isReversedOrder = order.value[0].codename !== "text_left___image_right"

  return (
    <S.Container data-is-reversed-order={isReversedOrder ? "" : undefined}>
      <S.TextParent data-text-parent>
        <TitleLAlt as="h3">{title.value}</TitleLAlt>
        <Paragraph>{description.value}</Paragraph>
      </S.TextParent>
      <S.ImageParent data-image-parent>
        <LazyImage
          ratio={[ratio, tabletRatio, ratio]}
          alt={image.value[0].description}
          sources={[
            getKontentImage(image.value[0].url, { width: 600 }),
            getKontentImage(tablet_image.value[0].url, { width: 500 }),
            getKontentImage(image.value[0].url, { width: 700 }),
            getKontentImage(image.value[0].url, { width: 900 }),
          ]}
          placeholder={getKontentImage(image.value[0].url, { width: 50 })}
        />
      </S.ImageParent>
    </S.Container>
  )
}
