import React from "react"
import { Box } from "@components/Layout"
import { rem } from "polished"
import { TitleL } from "@components/Typography"
import styled from "styled-components"
import { breakpoints } from "@config/theme"

const LineTitle = styled(TitleL)`
  position: relative;
  display: inline-block;
  margin: 0 0 2rem;
  &::before,
  &::after {
    @media screen and (max-width: ${breakpoints.small}) {
      display: none;
    }
    position: absolute;
    width: 4rem;
    height: 1px;
    content: "";
    background: ${(p) => p.theme.colors.text.dark};

    top: 50%;
  }
  &::before {
    left: -5rem;
  }
  &::after {
    right: -5rem;
  }
`

const Container = styled(Box)`
  p {
    max-width: ${rem(650)};
    margin: 0 auto;
  }
`

interface Props {
  title: string | null
  titleAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

const CenteredTextBlock: React.FC<Props> = ({
  children,
  title,
  titleAs = "h2",
}) => {
  return (
    <Container mt={["4rem", rem(140)]} mb="4.5rem" textAlign="center">
      <LineTitle>{title}</LineTitle>
      {children}
    </Container>
  )
}

export default CenteredTextBlock
