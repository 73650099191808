import ProductCombinationCard from "@components/Cards/ProductCombinationCard"
import { useIntl } from "@components/Intl"
import { Box } from "@components/Layout"
import { productGroupToUid } from "@config/general"
import imgix from "@config/imgix"
import { getGraphQLClient } from "@lib/graphql-client"
import { getKontentImage } from "@lib/kontent"
import { CbImageProduct as CbImageProductModel } from "@lib/kontent/models/CbImageProduct"
import { useQuery } from "@tanstack/react-query"
import { FC } from "react"
import * as S from "./elements"

const { sdk } = getGraphQLClient()

type Props = CbImageProductModel

const CbImageProduct: FC<Props> = ({
  mobile_image,
  desktop_image,
  product_id,
  order,
}) => {
  const { messages } = useIntl()
  const isImageFirst = order.value[0]?.codename === "image_left___product_right"
  const { data, isSuccess } = useQuery(
    ["articleCombination", product_id.value],
    () =>
      sdk.ArticleCombination({
        id: product_id.value,
      })
  )
  const uid =
    data && data.choicesArticleCombination
      ? productGroupToUid[data.choicesArticleCombination.product_group]
      : undefined

  return (
    <S.Container
      mb={{ _: "2rem", small: "3rem", large: "4rem" }}
      isImageFirst={isImageFirst}
    >
      <Box
        display={["block", "none"]}
        position="relative"
        gridRow="1"
        gridColumn={isImageFirst ? 1 : 2}
      >
        <S.Img
          loading="lazy"
          alt={mobile_image.value[0].description}
          src={getKontentImage(mobile_image.value[0].url, { width: 500 })}
        />
      </Box>
      <Box
        display={["none", "block"]}
        position="relative"
        gridRow="1"
        height="100%"
        overflow="hidden"
        gridColumn={isImageFirst ? 1 : 2}
      >
        <S.Img
          loading="lazy"
          alt={desktop_image.value[0].description}
          src={getKontentImage(desktop_image.value[0].url, { width: 1000 })}
        />
      </Box>
      <Box gridRow="1" gridColumn={isImageFirst ? 2 : 1}>
        {isSuccess && data.choicesArticleCombination ? (
          <ProductCombinationCard
            href={`${messages.navigation.collectionProductDetail.url}`}
            as={`${messages.navigation[uid].url}/${data.choicesArticleCombination.slug}-${data.choicesArticleCombination.id}`}
            productImage={
              data.choicesArticleCombination.images
                ? imgix({
                    key: data.choicesArticleCombination.images[0].path.replace(
                      ".png",
                      ""
                    ),
                    type: "articleCombination",
                    args: {
                      w: 500,
                      h: 500,
                    },
                  })
                : undefined
            }
            price={data.choicesArticleCombination.price}
            title={data.choicesArticleCombination.name}
          />
        ) : (
          <ProductCombinationCard href="" isLoading />
        )}
      </Box>
    </S.Container>
  )
}

export default CbImageProduct
