import { Container } from "@components/Layout"
import React, { FC } from "react"
import { CbRichContent } from "@lib/kontent/models/CbRichContent"
import RichContent from "@components/RichContentBlock"
import { Elements } from "@kentico/kontent-delivery"

type RichContentBlockProps = CbRichContent

export const RichContentBlock: FC<RichContentBlockProps> = ({ content }) => {
  return (
    <Container mb={{ _: "2rem", small: "4rem", large: "5rem" }}>
      <RichContent content={content as any as Elements.RichTextElement} />
    </Container>
  )
}
