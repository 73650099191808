import { ParagraphStyle } from "@components/Typography/TextStyles/Paragraph"
import { TitleSStyle } from "@components/Typography/TextStyles/TitleS"
import { TitleXSStyle } from "@components/Typography/TextStyles/TitleXS"
import { breakpoints } from "@config/theme"
import styled from "styled-components"

const mediumMb = `
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${breakpoints.medium}) {
    margin-bottom: 0.75rem;
  }
`

const regularMb = `
  margin-bottom: 1.5rem;
`

// @ts-ignore
export const Parent = styled.div`
  h1 {
    ${TitleSStyle};
    ${mediumMb};
  }

  h2 {
    ${TitleXSStyle};
    ${mediumMb};
  }

  h3 {
    ${TitleXSStyle};
    ${mediumMb};
  }

  h4 {
    ${TitleXSStyle};
    margin-bottom: 0.5rem;
  }

  p {
    ${ParagraphStyle};
    ${regularMb};
  }

  p + p {
    margin-top: -1rem;
  }

  ul {
    ${ParagraphStyle};
    line-height: 1;
    ${regularMb};

    li {
      list-style-position: outside;
      margin-left: 2rem;

      @media screen and (min-width: ${breakpoints.large}) {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  ol {
    ${ParagraphStyle};
    line-height: 1;
    ${regularMb};

    li {
      list-style-position: outside;
      margin-left: 2.25rem;
      padding-left: 0.25rem;

      @media screen and (min-width: ${breakpoints.large}) {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
`
