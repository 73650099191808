import { ButtonAnchor } from "@components/Buttons"
import ProductCombinationCard from "@components/Cards/ProductCombinationCard"
import { useIntl } from "@components/Intl"
import { Container } from "@components/Layout"
import { Paragraph, TitleL } from "@components/Typography"
import { productGroupToUid } from "@config/general"
import imgix from "@config/imgix"
import { ChoicesArticleCombination } from "@generated"
import { getGraphQLClient } from "@lib/graphql-client"
import { getKontentImage } from "@lib/kontent"
import { CbInspirationProducts } from "@lib/kontent/models/CbInspirationProducts"
import { CbSingleInspirationProductsImage } from "@lib/kontent/models/CbSingleInspirationProductsImage"
import { CbSingleInspirationProductsProduct } from "@lib/kontent/models/CbSingleInspirationProductsProduct"
import { useQuery } from "@tanstack/react-query"
import Link from "next/link"
import { FC, useEffect, useState } from "react"
import * as S from "./elements"

const { sdk } = getGraphQLClient()

type InspirationProductsProps = CbInspirationProducts & {
  items: (
    | CbSingleInspirationProductsImage
    | CbSingleInspirationProductsProduct
  )[]
}

const InspirationProducts: FC<InspirationProductsProps> = ({
  title,
  description,
  items,
  featured_image,
  element_order,
  view_more_text__optional_,
  view_more_link__optional_,
}) => {
  const { messages } = useIntl()
  const firstFourItems = items.slice(0, 4)
  const imageLeft =
    element_order.value[0].codename === "image_left___products_right"
  const [articles, setArticles] = useState<ChoicesArticleCombination[] | null>(
    null
  )
  const productIds = firstFourItems
    .map((item: any) => item.product_id && item.product_id.value)
    .filter((i) => i)
  const { data: articleCombinationData } = useQuery(
    ["articleCombinations", productIds],
    () => sdk.ArticleCombinationsById({ ids: productIds })
  )

  useEffect(() => {
    if (
      articleCombinationData &&
      articleCombinationData.choicesArticleCombinationsById
    ) {
      let products: ChoicesArticleCombination[] = []

      for (const id of productIds) {
        const product =
          articleCombinationData.choicesArticleCombinationsById.find(
            (item) => item.id === id
          )
        if (product) {
          products.push(product as ChoicesArticleCombination)
        }
      }
      setArticles(products)
    }
  }, [articleCombinationData]) // eslint-disable-line

  return (
    <Container>
      <S.Parent data-image-left={imageLeft ? "" : undefined}>
        <S.ImageParent>
          <S.Image
            src={getKontentImage(featured_image.value[0].url, { width: 1200 })}
            alt="Product image"
          />
        </S.ImageParent>
        <S.ProductsParent>
          <S.TitleArea>
            <TitleL as="h2" mb="0">
              {title.value}
            </TitleL>
            <Paragraph mb="8">{description.value}</Paragraph>
          </S.TitleArea>
          <S.ItemGrid>
            {firstFourItems.map((item: any, key) => {
              if (item.product_id) {
                const article = articles?.find(
                  (article) => article.id === item.product_id.value
                )
                if (article) {
                  const uid = productGroupToUid[article.product_group]
                  if (!uid) return null
                  return (
                    <ProductCombinationCard
                      key={article.combi_no}
                      href={`${messages.navigation.collectionProductDetail.url}`}
                      as={`${messages.navigation[uid].url}/${article.slug}-${article.id}`}
                      productImage={
                        article.images
                          ? imgix({
                              key: article.images[0].path.replace(".png", ""),
                              type: "articleCombination",
                              args: {
                                w: 500,
                                h: 500,
                              },
                            })
                          : undefined
                      }
                      price={article.price}
                      title={article.name}
                    />
                  )
                } else {
                  return null
                }
              } else {
                return (
                  <S.Image
                    key={key}
                    loading="lazy"
                    src={getKontentImage(item.image.value[0].url, {
                      width: 1200,
                    })}
                    alt="Product"
                  />
                )
              }
            })}
          </S.ItemGrid>
          {view_more_text__optional_.value &&
            view_more_link__optional_.value && (
              <S.ReadMore>
                <Link href={view_more_link__optional_.value}>
                  <ButtonAnchor variant="purple">
                    {view_more_text__optional_.value}
                  </ButtonAnchor>
                </Link>
              </S.ReadMore>
            )}
        </S.ProductsParent>
      </S.Parent>
    </Container>
  )
}

export default InspirationProducts
