import { FC, Fragment } from "react"
import Spacer from "./Spacer"
import CbIsolatedText from "./blocks/IsolatedText"
import IsolatedTitle from "./blocks/IsolatedTitle"
import { RichContentBlock } from "./blocks/RichContentBlock"
import { CbAutoplayVideo } from "./blocks/autoplay-video"
import { CbCarouselBoxed } from "./blocks/carousel-boxed"
import Collections from "./blocks/collections"
import { DualTripleImages } from "./blocks/dual-triple-images"
import InspirationProducts from "./blocks/inspiration-products"
import { CbProductRow } from "./blocks/product-row"
import { CbSingleImage } from "./blocks/single-image"
import { TextImageBoxed } from "./blocks/text-image-boxed"
import ThreeImages from "./blocks/three-images"
import { CbVideo } from "./blocks/video"
import CbImageProduct from "./blocks/image-product"

const blockTypes = [
  "cb___isolated_title",
  "cb___3_images",
  "cb___inspiration___products",
  "cb___collections",
  "cb___rich_content",
  "cb___text___image_boxed",
  "cb___dual_triple_text___image",
  "cb___video",
  "cb___carousel_boxed",
]

export type ContentBlocksProps = {
  contentBlocks: {
    type: (typeof blockTypes)[number]
    elements: any
  }[]
  modularContent: {
    [key: string]: any
  }
}

const ContentBlocks: FC<ContentBlocksProps> = ({
  contentBlocks,
  modularContent,
}) => {
  return (
    <>
      {contentBlocks.map((block, index) => {
        switch (block.type) {
          case "cb___isolated_title":
            return <IsolatedTitle key={index} {...block.elements} />
          case "cb___rich_content":
            return <RichContentBlock key={index} {...block.elements} />
          case "cb___3_images":
            return (
              <ThreeImages
                key={index}
                title={block.elements.title.value}
                description={block.elements.description.value}
                items={block.elements.items.value.map(
                  (key) => modularContent[key].elements
                )}
              />
            )
          case "cb___inspiration___products":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <InspirationProducts
                  {...block.elements}
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements
                  )}
                />
              </Fragment>
            )
          case "cb___collections":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <Collections
                  {...block.elements}
                  items={block.elements.items.value.map(
                    (key) => modularContent[key].elements
                  )}
                />
              </Fragment>
            )
          case "cb___text___image_boxed":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <TextImageBoxed {...block.elements} />
              </Fragment>
            )
          case "cb___dual_triple_text___image":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <DualTripleImages
                  items={block.elements.items.modular_content.map(
                    (key) => modularContent[key].elements
                  )}
                />
              </Fragment>
            )
          case "cb___video":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <CbVideo {...block.elements} />
              </Fragment>
            )
          case "cb___carousel_boxed":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <CbCarouselBoxed {...block.elements} />
              </Fragment>
            )
          case "cb___product_row":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <CbProductRow {...block.elements} />
              </Fragment>
            )
          case "cb___autoplay_video":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <CbAutoplayVideo {...block.elements} />
              </Fragment>
            )
          case "cb___single_image":
            return (
              <Fragment key={index}>
                {index === 0 && <Spacer />}
                <CbSingleImage {...block.elements} />
              </Fragment>
            )
          case "cb___isolated_text":
            return <CbIsolatedText key={index} {...block.elements} />
          case "cb___image___product":
            return <CbImageProduct key={index} {...block.elements} />
          default:
            return null
        }
      })}
    </>
  )
}

export default ContentBlocks
