import React, { FC } from "react"
import { Elements } from "@kentico/kontent-delivery"
import { Parent } from "./elements"
import { RichTextElement } from "@lib/kontent/models/Elements"

type Props = {
  content: Elements.RichTextElement | RichTextElement
}

const RichContent: FC<Props> = ({ content }) => {
  return <Parent dangerouslySetInnerHTML={{ __html: content.value }} />
}

export default RichContent
