import styled, { css } from "styled-components"
import { Paragraph } from "@components/Typography"
import { Box } from "@components/Layout"
import { breakpoints } from "@config/theme"

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${(p) => p.theme.colors.softGrey};
  margin-bottom: 1.25rem;
  @media screen and (min-width: ${breakpoints.small}) {
    margin-bottom: 2.5rem;
  }
  img,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  &::before {
    padding-top: 83%;
    content: "";
    display: block;
    @media screen and (min-width: ${breakpoints.small}) {
      padding-top: 72%;
    }
  }
`
// @ts-ignore
export const Item = styled(Box)`
  ${Paragraph} {
    max-width: 80%;
    margin: 0 auto;
    @media screen and (min-width: ${breakpoints.small}) {
      margin: 0 0;
      max-width: 100%;
    }
    @media screen and (min-width: ${breakpoints.medium}) {
      max-width: 80%;
    }
  }
`

export const PreviewImg = styled.img<{ show: boolean }>`
  opacity: 0;
  transition: all 0.5s ease-in-out;

  ${(p) =>
    p.show &&
    css`
      opacity: 1;
    `}
`
