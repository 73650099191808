import LazyImage from "@components/LazyImage"
import { getImageRatio } from "@lib/get-image-ratio"
import { getKontentImage } from "@lib/kontent"
import { CbSingleImage as CbSingleImageModel } from "@lib/kontent/models/CbSingleImage"
import { FC } from "react"
import * as S from "./elements"

type CbSingleImageProps = CbSingleImageModel

export const CbSingleImage: FC<CbSingleImageProps> = ({
  image,
  annotation_text,
  variant,
}) => {
  const ratio = image && getImageRatio(image)
  const isWide = variant.value[0].codename === "wide"

  return (
    <S.Container isWide={isWide}>
      <S.ImageParent>
        <LazyImage
          ratio={ratio}
          alt={image.value[0].description}
          sources={[
            getKontentImage(image.value[0].url, { width: 600 }),
            getKontentImage(image.value[0].url, { width: 500 }),
            getKontentImage(image.value[0].url, { width: 700 }),
            getKontentImage(image.value[0].url, { width: 900 }),
            getKontentImage(image.value[0].url, { width: 1200 }),
            getKontentImage(image.value[0].url, { width: 1600 }),
          ]}
          placeholder={getKontentImage(image.value[0].url, { width: 50 })}
        />
      </S.ImageParent>
      {annotation_text.value && (
        <S.Annotation isWide={isWide}>{annotation_text.value}</S.Annotation>
      )}
    </S.Container>
  )
}
