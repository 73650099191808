import { Container as UiContainer } from "@components/Layout"
import { ParagraphS } from "@components/Typography"
import { breakpoints, theme } from "@config/theme"
import styled from "styled-components"

export const Container = styled(UiContainer)<{ isWide: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 54rem;

  @media (min-width: ${breakpoints.small}) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 4rem;
  }

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1.7fr;
    margin-bottom: 5rem;
  }

  ${(p) =>
    p.isWide &&
    `
    width: 100%;
    max-width: 120rem;
  `}
`

export const ImageParent = styled.div`
  width: 100%;
`

export const Annotation = styled(ParagraphS)<{ isWide: boolean }>`
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${theme.colors.text.light};

  ${(p) =>
    p.isWide &&
    `
    padding-left: 1rem;
  `}
`
