import styled, { css } from "styled-components"
import { Box } from "../Layout"

type ImageContainerProps = {
  ratio?: number[]
  height?: string | number
  width?: string | number
}

export const ImageContainer = styled(Box)<ImageContainerProps>`
  display: flex;
  overflow: hidden;
  width: 100%;
  ${(p) =>
    p.ratio
      ? css`
          &::before {
            padding-top: ${p.ratio[0] * 100}%;
            content: "";
            display: block;
            width: 100%;
            ${p.ratio.length > 1 &&
            p.ratio.slice(1).map(
              (ratio, index) => css`
                @media screen and (min-width: ${p.theme.breakpoints[
                    index + 1
                  ]}) {
                  padding-top: ${ratio * 100}%;
                }
              `
            )}
          }
        `
      : css`
          width: ${p.width || "auto"};
          height: ${p.height || "auto"};
        `}
`

export const Placeholder = styled.img<{
  isVisible: boolean
  objectFit: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: blur(1rem);
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  object-fit: ${(p) => p.objectFit};
`

export const Picture = styled.picture<{
  isVisible?: boolean
  objectFit: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  z-index: 2;

  img {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 0.75rem;
    justify-content: center;
    object-fit: ${(p) => p.objectFit};
  }
`
