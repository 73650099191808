import ProductCombinationCard from "@components/Cards/ProductCombinationCard"
import { useIntl } from "@components/Intl"
import { Box } from "@components/Layout"
import { productGroupToUid } from "@config/general"
import imgix from "@config/imgix"
import { getGraphQLClient } from "@lib/graphql-client"
import { CbProductRow as CbProductRowModel } from "@lib/kontent/models/CbProductRow"
import { useQuery } from "@tanstack/react-query"
import { rem } from "polished"
import { FC } from "react"
import { ProductRowSliderItem } from "./SliderItem"
import { ProductRowTrack } from "./Track"

type CbProductRowProps = CbProductRowModel

const { sdk } = getGraphQLClient()

export const CbProductRow: FC<CbProductRowProps> = ({
  article_combinations,
}) => {
  const { messages } = useIntl()
  const productIds = article_combinations.value
    .replace(/ /g, "")
    .split(",")
    .filter((item) => item)
  const { data, isFetching } = useQuery(
    ["articleCombinations", productIds],
    () => sdk.ArticleCombinationsById({ ids: productIds.slice(0, 16) })
  )

  const hasData = data && data.choicesArticleCombinationsById && !isFetching
  const itemCount = hasData ? data.choicesArticleCombinationsById.length : 3

  if (productIds.length < 3) {
    return null
  }

  return (
    <Box
      as="section"
      m={"0 auto"}
      width={["100%", "100%", "100%", "calc(100% - 5.5rem)"]}
      maxWidth={rem(1200)}
      pl={["1rem", "2rem", "2rem", "0rem"]}
      mb={["2rem", "4rem", "4rem", "5rem"]}
    >
      {hasData ? (
        <ProductRowTrack
          key="item-slider"
          itemCount={data.choicesArticleCombinationsById.length}
        >
          {data.choicesArticleCombinationsById.map((article, index) => {
            const uid = productGroupToUid[article.product_group]
            if (!uid) return null
            return (
              <ProductRowSliderItem
                key={index}
                index={index}
                itemCount={itemCount}
              >
                <ProductCombinationCard
                  key={`${article.id}-${index}`}
                  href={`${messages.navigation.collectionProductDetail.url}`}
                  as={`${messages.navigation[uid].url}/${article.slug}-${article.id}`}
                  productImage={
                    article.images
                      ? imgix({
                          key: article.images[0].path.replace(".png", ""),
                          type: "articleCombination",
                          args: {
                            w: 500,
                            h: 500,
                          },
                        })
                      : undefined
                  }
                  price={article.price}
                  title={article.name}
                />
              </ProductRowSliderItem>
            )
          })}
        </ProductRowTrack>
      ) : (
        <ProductRowTrack key="loading-slider" itemCount={3}>
          {Array.from({ length: 3 }).map((_, index) => (
            <ProductRowSliderItem
              key={index}
              index={index}
              itemCount={itemCount}
            >
              <ProductCombinationCard key={index} href="" isLoading />
            </ProductRowSliderItem>
          ))}
        </ProductRowTrack>
      )}
    </Box>
  )
}
