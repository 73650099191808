import { Container as UiContainer } from "@components/Layout"
import { breakpoints } from "@config/theme"
import styled from "styled-components"

export const Container = styled(UiContainer)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: 1.5rem;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (min-width: ${breakpoints.small}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2.5rem;
    margin-bottom: 4rem;
  }

  @media (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1.7fr;
    grid-gap: 6rem;
    margin-bottom: 5rem;
  }

  &[data-is-reversed-order] {
    flex-direction: row-reverse;

    @media (min-width: ${breakpoints.large}) {
      grid-template-columns: 1.7fr 1fr;
    }

    [data-text-parent] {
      @media (min-width: ${breakpoints.small}) {
        text-align: right;
        grid-column: 2;
      }
    }

    [data-image-parent] {
      @media (min-width: ${breakpoints.small}) {
        grid-column: 1;
      }
    }
  }
`

export const TextParent = styled.div`
  text-align: center;
  width: 100%;
  grid-column: 1;
  grid-row: 2;

  @media (min-width: ${breakpoints.small}) {
    text-align: left;
    grid-column: 1;
    grid-row: 1;
  }
`

export const ImageParent = styled.div`
  grid-column: initial;

  @media (min-width: ${breakpoints.small}) {
    grid-column: 2;
  }
`
