import { breakpoints } from "@config/theme"
import styled from "styled-components"

export const Parent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "image"
    "products";
  grid-gap: 1.5rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${breakpoints.small}) {
    margin-bottom: 4rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    grid-template-areas: "products image";
    margin-bottom: 8rem;

    &[data-image-left] {
      grid-template-areas: "image products";
    }
  }
`

export const ImageParent = styled.div`
  grid-area: image;
  display: flex;
  align-self: flex-start;

  @media screen and (min-width: ${breakpoints.large}) {
    position: sticky;
    top: 8rem;
  }
`

export const ProductsParent = styled.div`
  grid-area: products;
`

export const TitleArea = styled.div`
  max-width: 700px;
  width: 100%;
`

export const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  @media screen and (min-width: ${breakpoints.large}) {
    grid-gap: 1.5rem;
  }
`

export const ReadMore = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.5rem;

  @media screen and (min-width: ${breakpoints.large}) {
    margin-top: 2rem;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`
