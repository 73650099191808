// @ts-strict-ignore
import { CleanButton } from "@components/Buttons"
import { Box } from "@components/Layout"
import LazyImage from "@components/LazyImage"
import { getKontentImage } from "@lib/kontent"
import * as Elements from "@lib/kontent/models/Elements"
import { FC } from "react"
import styled from "styled-components"
import { opacity, OpacityProps } from "styled-system"

// @ts-ignore
const Button = styled(CleanButton)<OpacityProps>`
  display: flex;
  min-width: 15%;
  padding: 0;
  scroll-snap-align: start;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
  ${opacity};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 1;
    }
  }
`
type Props = {
  images: Elements.AssetsElement["value"]
  onSelect: (number) => void
  index: number
}

const Thumbnails: FC<Props> = ({ images, onSelect, index }) => {
  return (
    <>
      {images.map((image, key) => (
        <Box display="contents" key={key}>
          <Button
            onClick={() => onSelect(key)}
            opacity={key === index ? 1 : 0.4}
          >
            <LazyImage
              ratio={0.65}
              alt={image.description}
              sources={[
                getKontentImage(image.url, { width: 300 }),
                getKontentImage(image.url, { width: 500 }),
              ]}
              placeholder={getKontentImage(image.url, { width: 30 })}
            />
          </Button>
          {key !== images.length - 1 && <Box minWidth="0.5rem" height="100%" />}
        </Box>
      ))}
    </>
  )
}

export default Thumbnails
