import React, { FC } from "react"

export const PlayIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      fill="none"
      viewBox="0 0 21 24"
    >
      <path
        fill="#ECE7E3"
        d="M0 12V1.685A1 1 0 011.479.807L20.39 11.122a1 1 0 010 1.756L1.48 23.193A1 1 0 010 22.315V12z"
      ></path>
    </svg>
  )
}
