import { Box } from "@components/Layout"
import React, { FC } from "react"

type ProductRowSliderItemProps = {
  itemCount: number
  index: number
  children: React.ReactNode
}

export const ProductRowSliderItem: FC<ProductRowSliderItemProps> = ({
  itemCount,
  index,
  children,
}) => {
  return (
    <Box
      minWidth={["64vw", "52vw", "52vw", "calc((100% / 3) - 1.5rem)"]}
      style={{
        scrollSnapAlign: index !== itemCount - 1 ? "start" : "end",
      }}
      mr={index !== itemCount - 1 && ["1rem", "2.25rem"]}
    >
      {children}
    </Box>
  )
}
