import { LinkButton } from "@components/Buttons"
import CenteredTextBlock from "@components/ContentPage/CenteredTextBlock"
import { Box, Container } from "@components/Layout"
import { Paragraph, ParagraphTitle } from "@components/Typography"
import { getKontentImage } from "@lib/kontent"
import { HomepageIntroBlock } from "@lib/kontent/models/HomepageIntroBlock"
import React from "react"
import VideoInline from "./Video"
import * as S from "./elements"
import Link from "next/link"

type Props = {
  title: string
  description: string
  items: HomepageIntroBlock[]
}

const ThreeImages: React.FC<Props> = ({ title, description, items }) => {
  return (
    <Container>
      <CenteredTextBlock titleAs="h2" title={title}>
        <Paragraph>{description}</Paragraph>
      </CenteredTextBlock>

      <Box
        display="grid"
        gridTemplateColumns={["100%", "repeat(3, 1fr)"]}
        gridColumnGap={["1rem", "1rem", "1.5rem"]}
        marginBottom={["2rem", "4rem", "4rem", "8rem"]}
      >
        {items.slice(0, 3).map((block, index) => (
          <S.Item
            key={`block-${index}`}
            mb={[5, 0]}
            textAlign={["center", "left"]}
          >
            <S.ImageContainer>
              {block.video.value && block.video.value[0] ? (
                <VideoInline
                  src={block.video.value[0].url}
                  previewImg={getKontentImage(block.image.value[0]?.url)}
                />
              ) : (
                block.image.value &&
                block.image.value[0].url && (
                  <img
                    loading="lazy"
                    src={getKontentImage(block.image.value[0].url)}
                    alt={block.title.value}
                  ></img>
                )
              )}
            </S.ImageContainer>
            <ParagraphTitle>{block.title.value}</ParagraphTitle>
            <Paragraph>{block.text.value}</Paragraph>
            {block.link_url.value && block.link_title.value && (
              <Link href={block.link_url.value} passHref>
                <LinkButton as="link" href={block.link_url.value}>
                  {block.link_title.value}
                </LinkButton>
              </Link>
            )}
          </S.Item>
        ))}
      </Box>
    </Container>
  )
}

export default ThreeImages
