import { ButtonAnchor } from "@components/Buttons"
import { Paragraph } from "@components/Typography"
import { breakpoints } from "@config/theme"
import { useBreakpoint } from "@lib/hooks/use-breakpoint"
import { CbAutoplayVideo as CbAutoplayVideoModel } from "@lib/kontent/models/CbAutoplayVideo"
import Link from "next/link"
import { FC, useRef, useState } from "react"
import { AudioDisabledIcon } from "./AudioDisabledIcon"
import { AudioEnabledIcon } from "./AudioEnabledIcon"
import * as S from "./elements"

type CbAutoplayVideoProps = CbAutoplayVideoModel

export const CbAutoplayVideo: FC<CbAutoplayVideoProps> = ({
  desktop_video,
  desktop_video_height,
  desktop_video_width,
  desktop_preview_image,
  mobile_preview_image,
  mobile_video,
  mobile_video_height,
  mobile_video_width,
  display_wide_boxed,
  title,
  description,
  button_text,
  button_url,
  title_tag,
}) => {
  const [muted, setMuted] = useState(true)
  const innerRef = useRef<HTMLVideoElement>(null)
  const isDesktop = useBreakpoint(breakpoints.large)
  const isBoxed = display_wide_boxed.value[0].codename === "boxed_variant"
  const previewImage = isDesktop ? desktop_preview_image : mobile_preview_image
  const video = isDesktop ? desktop_video : mobile_video
  const width = isDesktop ? desktop_video_width.value : mobile_video_width.value
  const height = isDesktop
    ? desktop_video_height.value
    : mobile_video_height.value

  const hasContent = title.value || description.value

  return (
    <S.Wrapper>
      <S.Parent
        position="relative"
        m={"0 auto"}
        width={isBoxed && "54rem"}
        videoHeight={height}
        videoWidth={width}
        maxWidth={
          isBoxed
            ? [
                "calc(100% - 4rem)",
                "calc(100% - 4rem)",
                "calc(100% - 4rem)",
                "calc(100% - 8rem)",
              ]
            : "120rem"
        }
      >
        <S.PreviewImg src={previewImage.value[0].url} alt="preview-img" />
        <S.Video
          src={video.value[0].url}
          height={height}
          width={width}
          autoPlay
          muted={muted}
          loop
          ref={innerRef}
        />
        {hasContent && !isBoxed && (
          <S.ContentParent>
            <S.Content>
              {title && (
                <S.LineTitle as={title_tag.value[0].codename as any} mb="0">
                  {title.value}
                </S.LineTitle>
              )}
              {description && <Paragraph>{description.value}</Paragraph>}
              {button_text.value && button_url.value && (
                <Link href={button_url.value}>
                  <ButtonAnchor variant="purple">
                    {button_text.value}
                  </ButtonAnchor>
                </Link>
              )}
            </S.Content>
          </S.ContentParent>
        )}

        <S.ToggleContainer>
          <S.AudioButton onClick={toggleAudio} tabIndex={-1}>
            {muted ? <AudioDisabledIcon /> : <AudioEnabledIcon />}
          </S.AudioButton>
        </S.ToggleContainer>
      </S.Parent>
    </S.Wrapper>
  )

  function toggleAudio() {
    setMuted((prev) => !prev)
  }
}
