import { Box, Container } from "@components/Layout"
import { TitleLAlt } from "@components/Typography"
import { CbIsolatedText as CbIsolatedTextModel } from "@lib/kontent/models/CbIsolatedText"
import { FC } from "react"

type Props = CbIsolatedTextModel

const CbIsolatedText: FC<Props> = ({ text, alignment }) => {
  const align = alignment?.value[0]?.codename || "center"

  return (
    <Container
      mt={{ _: "3rem", small: "4rem", large: "5rem" }}
      mb={{ _: "2rem", small: "3rem", large: "4rem" }}
      display="flex"
      justifyContent={
        align === "left"
          ? "flex-start"
          : align === "right"
          ? "flex-end"
          : "center"
      }
    >
      <Box maxWidth="50rem" textAlign={align as any}>
        <TitleLAlt as="h3" mb={0}>
          {text.value}
        </TitleLAlt>
      </Box>
    </Container>
  )
}

export default CbIsolatedText
