import { Box, Container } from "@components/Layout"
import { Track, useSlider } from "@components/Slider"
import { CbCarouselBoxed as CbCarouselBoxedModel } from "@lib/kontent/models/CbCarouselBoxed"
import { rem } from "polished"
import { FC, useRef } from "react"
import { useSwipeable } from "react-swipeable"
import ActiveSlide from "./ActiveSlide"
import SlideNav from "./SlideNav"
import Thumbnails from "./Thumbnails"

type CbCarouselBoxedProps = CbCarouselBoxedModel

export const CbCarouselBoxed: FC<CbCarouselBoxedProps> = ({ images }) => {
  const items = images.value
  const trackRef = useRef(null)
  const { goToIndex, index } = useSlider({
    trackRef,
  })

  const prev = () => {
    index - 1 >= 0 && goToIndex(index - 1)
  }

  const next = () => {
    index + 1 < items.length && goToIndex(index + 1)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
  })

  return (
    <Container maxWidth={rem(860)} mb={["2rem", "4rem", "4rem", "8rem"]}>
      <Box mb="0.5rem" position="relative" {...handlers}>
        <ActiveSlide image={items[index]} />
        <SlideNav images={items} index={index} onPrev={prev} onNext={next} />
      </Box>
      <Track ref={trackRef} display={["none", "flex"]}>
        <Thumbnails
          images={items}
          onSelect={(newIndex) => goToIndex(newIndex)}
          index={index}
        />
      </Track>
    </Container>
  )
}
