import { Box } from "@components/Layout"
import { breakpoints } from "@config/theme"
import { rem, rgba } from "polished"
import styled, { css } from "styled-components"

export const PreviewImg = styled.img<{ show: boolean }>`
  opacity: 0;
  transition: all 0.5s ease-in-out;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${(p) =>
    p.show &&
    css`
      opacity: 1;
    `}
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
`

export const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: ${breakpoints.small}) {
    margin-bottom: 4rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    margin-bottom: 8rem;
  }
`

// @ts-ignore
export const Parent = styled(Box)<{ videoHeight: number; videoWidth: number }>`
  position: relative;
  aspect-ratio: ${(p) => p.videoWidth} / ${(p) => p.videoHeight};
  overflow: hidden;
`

export const PlayButton = styled.button<{ status: string }>`
  position: relative;
  transition: background 0.25s ease-in-out;
  padding: 2rem;
  width: ${rem(70)};
  height: ${rem(70)};
  border-radius: ${rem(35)};
  color: ${(p) => p.theme.colors.beige};
  background: ${(p) => rgba(p.theme.colors.text.dark, 0.2)};
  border: 0;
  outline: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${(p) =>
    p.status !== "loading" &&
    css`
      &:hover,
      &:focus {
        background: ${(p) => rgba(p.theme.colors.text.dark, 0.4)};
      }
    `};
  ${(p) =>
    p.status === "loading" &&
    css`
      cursor: wait;
    `};
`

export const Content = styled.div<{ status: string }>`
  transition: opacity 0.25s ease-in-out;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => rgba(p.theme.colors.text.dark, 0.4)};
  opacity: ${(p) => (p.status === "playing" ? 0 : 1)};
  pointer-events: ${(p) => (p.status === "playing" ? "none" : "auto")};
`
