import { Box } from "@components/Layout"
import { Track, useSlider } from "@components/Slider"
import { CbSingleImageDualTripleImages } from "@lib/kontent/models/CbSingleImageDualTripleImages"
import { rem } from "polished"
import React, { FC, Fragment, useRef } from "react"
import { DualTripleImagesItem } from "./Item"

type DualTripleImagesTripleProps = {
  items: CbSingleImageDualTripleImages[]
}

export const DualTripleImagesTriple: FC<DualTripleImagesTripleProps> = ({
  items,
}) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const { onTouchEnd } = useSlider({ trackRef })

  return (
    <Box
      as="section"
      m={[0, 0, 0, "0 auto"]}
      width={["100%", "100%", "100%", "calc(100% - 5.5rem)"]}
      maxWidth={rem(1200)}
      mb={["2rem", "4rem", "4rem", "5rem"]}
    >
      <Track
        ref={trackRef}
        onTouchEnd={onTouchEnd}
        overflowY="hidden"
        display={["flex", "flex", "flex", "grid"]}
        gridTemplateColumns={"repeat(3, 1fr)"}
        gridGap={[0, 0, 0, "2.25rem"]}
      >
        <>
          {items.map((item, index) => (
            <Fragment key={index}>
              <Box
                display={["block", "block", "block", "none"]}
                minWidth={["1rem", "2rem"]}
                height="1px"
                style={{
                  scrollSnapAlign: index !== items.length - 1 && "start",
                }}
              />
              <Box
                minWidth={["64vw", "52vw", "52vw", "initial"]}
                mr={
                  index !== items.length - 1 && {
                    _: "0.5rem",
                    small: "1rem",
                    large: 0,
                  }
                }
              >
                <DualTripleImagesItem {...item} variant="triple" />
              </Box>
              {index === items.length - 1 && (
                <Box
                  display={["block", "block", "block", "none"]}
                  minWidth={["1rem", "2rem"]}
                  height="1px"
                  style={{ scrollSnapAlign: "end" }}
                />
              )}
            </Fragment>
          ))}
        </>
      </Track>
    </Box>
  )
}
