import { Container as UiContainer } from "@components/Layout"
import { breakpoints } from "@config/theme"
import { rem } from "polished"
import styled from "styled-components"

export const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const Container = styled(UiContainer)<{ isImageFirst: boolean }>`
  max-width: ${rem(1450)};
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1rem;

  @media screen and (min-width: ${breakpoints.small}) {
    gap: 1.5rem;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    grid-template-columns: ${(p) =>
      p.isImageFirst ? "1fr minmax(auto, 26rem)" : "minmax(auto, 26rem) 1fr"};
    gap: 2.5rem;
  }

  @media screen and (min-width: ${breakpoints.xlarge}) {
    gap: 4.5rem;
  }
`
