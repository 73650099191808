import { Box } from "@components/Layout"
import { TitleL } from "@components/Typography"
import { breakpoints } from "@config/theme"
import { rgba } from "polished"
import styled from "styled-components"

export const PreviewImg = styled.img`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: ${breakpoints.small}) {
    margin-bottom: 4rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    margin-bottom: 8rem;
  }
`

// @ts-ignore
export const Parent = styled(Box)<{ videoHeight: number; videoWidth: number }>`
  position: relative;
  aspect-ratio: ${(p) => p.videoWidth} / ${(p) => p.videoHeight};
  overflow: hidden;
`

export const AudioButton = styled.button`
  position: relative;
  color: ${(p) => p.theme.colors.beige};
  background-color: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 0;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

export const ContentParent = styled.div`
  position: absolute;
  background: ${rgba("#000000", 0.5)};
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 45rem;
  padding: 1rem;
  margin: 0 auto;

  @media screen and (min-width: ${breakpoints.small}) {
    gap: 0.5rem;
  }

  a {
    width: auto !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
`

export const ToggleContainer = styled.div`
  position: absolute;
  left: 1rem;
  bottom: 1rem;
`

export const LineTitle = styled(TitleL)`
  position: relative;
  display: inline-block;
  &::before,
  &::after {
    @media screen and (max-width: ${breakpoints.small}) {
      display: none;
    }
    position: absolute;
    width: 3rem;
    height: 1px;
    content: "";
    background: white;
    top: 50%;
  }
  &::before {
    left: -5rem;
  }
  &::after {
    right: -5rem;
  }
`
