import { Box } from "@components/Layout"
import { CbSingleImageDualTripleImages } from "@lib/kontent/models/CbSingleImageDualTripleImages"
import { rem } from "polished"
import React, { FC } from "react"
import { DualTripleImagesItem } from "./Item"

type DualTripleImageDoubleProps = {
  items: CbSingleImageDualTripleImages[]
}

export const DualTripleImageDouble: FC<DualTripleImageDoubleProps> = ({
  items,
}) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={["1fr", "1fr", "1fr", "repeat(2, 1fr)"]}
      gridColumnGap={[0, 0, 0, "4rem", "6.5rem"]}
      gridRowGap={["1rem", "1.5rem", "1.5rem", "2rem"]}
      m="0 auto"
      width={[
        "100%",
        "calc(100% - 8rem)",
        "calc(100% - 8rem)",
        "calc(100% - 5.5rem)",
      ]}
      maxWidth={rem(1200)}
      mb={"5rem"}
    >
      {items.map((item, index) => (
        <DualTripleImagesItem key={index} variant="double" {...item} />
      ))}
    </Box>
  )
}
