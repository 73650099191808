import React, {
  FC,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react"
import { useInView } from "react-intersection-observer"
import { SpaceProps } from "styled-system"
import { BoxProps } from "../Layout/Box"
import { theme } from "../../config/theme"
import * as S from "./elements"

const imageCache = {}

const inImageCache = (src: string) => imageCache[src] || false

const addToCache = (src: string) => {
  imageCache[src] = true
}

const getSources = (sources: string[]) =>
  sources
    .map((src: string, index: number) => (
      <source
        key={index}
        media={
          index !== 0 ? `(min-width: ${theme.breakpoints[index]})` : undefined
        }
        srcSet={src}
      />
    ))
    .reverse()

export type ImageProps = BoxProps &
  SpaceProps &
  Omit<ImgHTMLAttributes<HTMLImageElement>, "loading"> & {
    ratio?: number | number[]
    sources: string[]
    placeholderSrc?: string
    loading?: "lazy" | "eager" | "delayed" | "none"
    objectFit?: "cover" | "contain"
  }
export const LazyImage: FC<ImageProps> = ({
  ratio,
  sources,
  placeholderSrc,
  loading = "lazy",
  height,
  width,
  alt,
  position = "relative",
  bg = "primary",
  objectFit = "cover",
  ...rest
}) => {
  // base initial value on presence in cache.
  // if sources[0] is present in cache, never animate image in
  const [isImageLoaded, setIsImageLoaded] = useState(inImageCache(sources[0]))
  const [isMounted, setIsMounted] = useState(false)

  const pictureRef = useRef<any>()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "500px",
  })
  const isCritical = loading === "eager"
  const isLazy = loading === "lazy"
  const isDelayed = loading === "delayed"

  const isBrowser = typeof window !== "undefined"
  const hasIOSupport =
    isBrowser && typeof window.IntersectionObserver !== "undefined"
  const hasNativeLazyLoadSupport =
    typeof HTMLImageElement !== `undefined` &&
    `loading` in HTMLImageElement.prototype

  // Determine if the picture tag should be rendered
  // In these cases picture should render:
  // - When critical
  // - Lazy && No IO support
  // - Lazy && In View
  // - isDelayed && image is loaded
  const shouldRenderPicture =
    isCritical ||
    (isLazy && isBrowser && !hasIOSupport) ||
    (inView && isLazy) ||
    (isMounted && isDelayed)

  const isImageVisible = isCritical || !hasIOSupport || isImageLoaded // if loading is eager or no IO, or if image is loaded, set opacity to 1

  const handleOnLoad = () => {
    setIsImageLoaded(true)
    if (!inImageCache(sources[0])) {
      addToCache(sources[0])
    }
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <S.ImageContainer
      // If ratio is a number, convert it to an array
      ratio={typeof ratio === "number" ? [ratio] : ratio}
      height={height}
      width={width}
      position={position}
      ref={ref}
      suppressHydrationWarning
      {...rest}
    >
      {!isCritical && (!hasNativeLazyLoadSupport || loading === "none") ? (
        <S.Placeholder
          isVisible={!shouldRenderPicture}
          aria-hidden="true"
          src={placeholderSrc}
          alt=""
          objectFit={objectFit}
          {...rest}
        />
      ) : null}

      {shouldRenderPicture && (
        <S.Picture isVisible={isImageVisible} objectFit={objectFit}>
          {getSources(sources)}
          <img
            suppressHydrationWarning
            onLoad={handleOnLoad}
            src={sources[0]}
            ref={pictureRef}
            alt={alt}
            // @ts-ignore
            loading={
              hasNativeLazyLoadSupport && loading !== "delayed"
                ? loading
                : undefined
            }
          />
        </S.Picture>
      )}
    </S.ImageContainer>
  )
}

export default LazyImage
