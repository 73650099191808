import { Box } from "@components/Layout"
import LazyImage from "@components/LazyImage"
import { Paragraph, TitleLAlt } from "@components/Typography"
import { getImageRatio } from "@lib/get-image-ratio"
import { getKontentImage } from "@lib/kontent"
import { CbSingleImageDualTripleImages } from "@lib/kontent/models/CbSingleImageDualTripleImages"
import Link from "next/link"
import { FC } from "react"
import { LinkButton } from "@components/Buttons"

type DualTripleImagesItemProps = CbSingleImageDualTripleImages & {
  variant: "double" | "triple"
}

export const DualTripleImagesItem: FC<DualTripleImagesItemProps> = ({
  title,
  description,
  image,
  link_text__optional_,
  link_url__optional_,
  variant,
}) => {
  return (
    <Box mb={variant === "double" && ["2rem", "4rem"]}>
      <Box mb={["1rem", "1.5rem", "1.5rem", "2rem"]}>
        <LazyImage
          ratio={getImageRatio(image)}
          alt={image.value[0].description}
          sources={[
            getKontentImage(image.value[0].url, { width: 500 }),
            getKontentImage(image.value[0].url, { width: 700 }),
          ]}
          placeholder={getKontentImage(image.value[0].url, { width: 50 })}
        />
      </Box>
      <Box
        display="flex"
        textAlign={["left", "left", "left", "center"]}
        flexDirection="column"
        alignItems={["flex-start", "flex-start", "flex-start", "center"]}
        px={variant === "double" && ["1rem", "2rem", "2rem", "initial"]}
      >
        <TitleLAlt as="h3">{title.value}</TitleLAlt>
        <Paragraph>{description.value}</Paragraph>
        {link_text__optional_.value && link_url__optional_.value && (
          <Box display="inline-block">
            <Link href={link_url__optional_.value} passHref>
              <LinkButton href={link_url__optional_.value}>
                {link_text__optional_.value}
              </LinkButton>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  )
}
