import { CbSingleImageDualTripleImages } from "@lib/kontent/models/CbSingleImageDualTripleImages"
import React, { FC } from "react"
import { DualTripleImageDouble } from "./Double"
import { DualTripleImagesTriple } from "./Triple"

type DualTripleImagesProps = {
  items: CbSingleImageDualTripleImages[]
}

export const DualTripleImages: FC<DualTripleImagesProps> = ({ items }) => {
  if (![2, 3].includes(items.length)) {
    return null
  }

  return (
    <>
      {items.length === 3 ? (
        <DualTripleImagesTriple items={items} />
      ) : (
        <DualTripleImageDouble items={items} />
      )}
    </>
  )
}
