import { CollectionCard } from "@components/Cards/CollectionCard"
import CenteredTextBlock from "@components/ContentPage/CenteredTextBlock"
import { Box, Container } from "@components/Layout"
import { Paragraph } from "@components/Typography"
import { CbCollections } from "@lib/kontent/models/CbCollections"
import { CbSingleCollectionCollections } from "@lib/kontent/models/CbSingleCollectionCollections"
import React from "react"

type CollectionsProps = CbCollections & {
  items: CbSingleCollectionCollections[]
}

const Collections: React.FC<CollectionsProps> = ({
  title,
  description,
  items,
}) => {
  return (
    <Container maxWidth={1600} mb={{ _: "2rem", small: "4rem", large: "8rem" }}>
      <CenteredTextBlock title={title.value}>
        <Paragraph>{description.value}</Paragraph>
      </CenteredTextBlock>
      <Box
        display="grid"
        gridTemplateColumns={[
          "100%",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(3, 1fr)",
          "repeat(6, 1fr)",
        ]}
        gridColumnGap={["1rem", "1.5rem"]}
        gridRowGap={["1rem", "1.5rem"]}
      >
        {items.map((item, key) => (
          <CollectionCard {...item} key={key} />
        ))}
      </Box>
    </Container>
  )
}

export default Collections
